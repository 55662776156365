import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import bg from "../images/backgrounds/404-bg.webp"

export default function NotFoundPage() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>404 - Not Found</title>
                <body className="bg-neutral-100 overflow-x-hidden max-w-full"/>
            </Helmet>
            <div className="h-screen w-screen flex flex-col scroll-smooth dark:text-white">
                <Navbar />

                <div className="grow w-screen bg-fuchsia-500 flex flex-col justify-center items-center bg-center bg-cover"  style={{backgroundImage: `url(${bg})`}}>

                    <div className="w-fit h-fit p-20 flex flex-col justify-center items-center bg-gray-700/30 backdrop-blur-md rounded-xl">
                        <div className="flex flex-col justify-center items-center">
                            
                            <div>
                                <text className="font-geometry text-sand text-3xl self-start">ERROR</text>
                                <h1 className="-mt-28 -mb-16 font-geometry text-[18rem]">404</h1>
                            </div>
                            

                            <text className="font-extralight text-xl">"If an item does not appear in our records, it does not exist!"</text>
                            <text className="font-semibold text-xl">Jedi Master Jocasta Nu</text>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}